import { PlusOutlined } from '@ant-design/icons'
import { Button, Form, InputNumber, message } from 'antd'
import React, { useEffect, useState } from 'react'

import { useAddAuctionMutation } from './auctions.api'

type Props = {
  auctionIds: number[]
}

export function AddAuctionForm({ auctionIds }: Props) {
  const [isCreatingAuction, setIsCreatingAuction] = useState<boolean>(false)
  const [form] = Form.useForm()

  const [addAuction, { isLoading, isError, isSuccess, error, data }] =
    useAddAuctionMutation()

  useEffect(() => {
    if (isLoading) {
      setIsCreatingAuction(true)
    }
    if (isSuccess) {
      setIsCreatingAuction(false)
      message.success({
        content: `Добавлена "${data?.originId}: ${data?.name}"`,
      })
    }
    if (isError) {
      setIsCreatingAuction(false)
      const messages = (error as any).data.message
      if (messages instanceof Array) {
        messages.forEach((m: any) => message.error(m))
      } else {
        message.error(messages)
      }
    }
  }, [isLoading, isSuccess, isError, error, data])
  return (
    <Form
      layout="inline"
      form={form}
      onFinish={value => {
        addAuction(value)
        form.resetFields()
      }}
      initialValues={{ auctionId: null }}
    >
      <Form.Item
        name="auctionId"
        validateFirst={false}
        rules={[
          {
            required: true,
            message: 'Введите номер котировочной сессии',
          },
          {
            validator(_, value) {
              if (!value || !auctionIds.includes(value)) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error(`Котировочная сессия ${value} уже добавлена`)
              )
            },
          },
        ]}
      >
        <InputNumber min={9000000} max={9999999} />
      </Form.Item>
      <Button
        loading={isCreatingAuction}
        htmlType="submit"
        type="primary"
        disabled={false}
        size="middle"
      >
        <PlusOutlined />
      </Button>
    </Form>
  )
}
