import { PlusOutlined } from '@ant-design/icons'
import { Button, Card, Col, Image, Row, Space, Typography } from 'antd'
import React, { useState } from 'react'

import { rub, units } from '../../../app/utils'
import { YandexIcon } from '../../../components/CustomIcon/YandexIcon'
import {
  CreateSearchProductQuery,
  useAddSearchProductMutation,
} from '../auctions.api'
import { ICreateSearchProductDto, IProduct } from '../auctions.interface'
import { SearchProductCardItem } from './SearchProduct/SearchProductCardItem'
import { SearchProductCreateForm } from './SearchProduct/SearchProductCreateForm'

const { Paragraph, Text } = Typography

type Props = {
  product: IProduct
}

export function ProductCard({ product }: Props) {
  const [confirmLoading, setConfirmLoading] = useState(false)

  const [addSearchProduct] =
    useAddSearchProductMutation<CreateSearchProductQuery>()

  const [visible, setVisible] = useState(false)

  const onCreate = async (values: ICreateSearchProductDto) => {
    setConfirmLoading(true)
    await addSearchProduct({
      productId: product.originId,
      initialValue: values,
    })
    setVisible(false)
    setConfirmLoading(false)
  }

  return (
    <Card hoverable>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 8 }} sm={{ span: 6 }}>
          <Image
            src={`https://zakupki.mos.ru/newapi/api/Core/Thumbnail/${
              product.imageId ?? product.defaultImageId
            }/800/800`}
          />
        </Col>
        <Col xs={{ span: 16 }} sm={{ span: 18 }}>
          <Text strong style={{ userSelect: 'none' }}>
            {product.name}
          </Text>
        </Col>
        <Col xs={{ span: 12 }} sm={{ span: 6 }}>
          <Paragraph disabled>Количество</Paragraph>
          <Paragraph>{units(product.units)}</Paragraph>
        </Col>
        <Col xs={{ span: 12 }} sm={{ span: 6 }}>
          <Paragraph disabled>Цена за ед.</Paragraph>
          <Paragraph>{rub(product.costPerUnit)}</Paragraph>
        </Col>
        <Col xs={{ span: 12 }} sm={{ span: 6 }}>
          <Paragraph disabled>Общая стоимость</Paragraph>
          <Paragraph>{rub(product.total, 0)}</Paragraph>
        </Col>
        <Col xs={{ span: 12 }} sm={{ span: 6 }}>
          <Paragraph disabled>Действия</Paragraph>
          <Paragraph>
            <Space>
              <a
                href={`https://yandex.ru/search/?text=${product.name}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button size="small" danger>
                  <YandexIcon />
                </Button>
              </a>
              <Button
                onClick={() => {
                  setVisible(true)
                }}
                type="primary"
                size="small"
              >
                <PlusOutlined />
              </Button>
              <SearchProductCreateForm
                visible={visible}
                confirmLoading={confirmLoading}
                onCreate={onCreate}
                onCancel={() => {
                  setVisible(false)
                }}
              />
            </Space>
          </Paragraph>
        </Col>
        <Col span={24}>
          {product.searchProducts.map(searchProduct => (
            <SearchProductCardItem
              key={searchProduct.id}
              searchProduct={searchProduct}
            />
          ))}
        </Col>
      </Row>
    </Card>
  )
}
