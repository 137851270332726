import { Card, Col, Row, Typography } from 'antd'
import React from 'react'

import { percent, rub } from '../../app/utils'
import { IAuction } from './auctions.interface'

const { Paragraph } = Typography

type Props = {
  auction: IAuction
}

export function AuctionAnalysisCard({ auction }: Props) {
  return (
    <Card hoverable>
      <Row style={{ marginTop: 10 }}>
        <Col xs={{ span: 12 }} sm={{ span: 6 }}>
          <Paragraph disabled>Выплата по контракту</Paragraph>
          <Paragraph>{rub(auction.startCost, 0)}</Paragraph>
        </Col>
        <Col xs={{ span: 12 }} sm={{ span: 6 }}>
          <Paragraph disabled>Себестоимость котракта</Paragraph>
          <Paragraph>{rub(auction.auctionCost, 0)}</Paragraph>
        </Col>
        <Col xs={{ span: 12 }} sm={{ span: 6 }}>
          <Paragraph disabled>Чистая прибыль</Paragraph>
          <Paragraph>{rub(auction.netProfit, 0)}</Paragraph>
        </Col>
        <Col xs={{ span: 12 }} sm={{ span: 6 }}>
          <Paragraph disabled>Процент чистой прибыли</Paragraph>
          <Paragraph>{percent(auction.netProfitPercentage, 0)}</Paragraph>
        </Col>
        <Col xs={{ span: 24 }}>
          <Paragraph disabled>Документы</Paragraph>
          {auction.files.map(file => (
            <Paragraph key={file.id}>
              <a
                href={`https://zakupki.mos.ru/newapi/api/FileStorage/Download?id=${file.id}`}
              >
                {file.name}
              </a>
            </Paragraph>
          ))}
        </Col>
      </Row>
    </Card>
  )
}
