import { Space } from 'antd'
import React from 'react'

import { AuctionCard } from './AuctionCard'
import { IAuctions } from './auctions.interface'

type Props = {
  auctions: IAuctions[]
}

export function AuctionsList({ auctions }: Props) {
  return (
    <section>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        {[...auctions]
          .sort(
            (a, b) => Number(new Date(a.endDate)) - Number(new Date(b.endDate))
          )
          .map(auction => (
            <AuctionCard key={auction.originId} auction={auction} />
          ))}
      </Space>
    </section>
  )
}
