import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Card, Col, Row, Space, Typography } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import { date, rub } from '../../app/utils'
import { ZakupkiIcon } from '../../components/ZakupkiIcon/ZakupkiIcon'
import styles from './AuctionCard.module.less'
import { useRemoveAuctionMutation } from './auctions.api'
import { IAuctions } from './auctions.interface'

const { Paragraph } = Typography

type Props = {
  auction: IAuctions
}

export function AuctionCard({ auction }: Props) {
  const [removeAuction] = useRemoveAuctionMutation()

  return (
    <Card hoverable>
      <Link to={`/auctions/${auction.originId}`}>
        <Paragraph strong>{auction.name}</Paragraph>
      </Link>
      <Paragraph>{auction.customer.name}</Paragraph>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 8 }} className={styles.column}>
          <Paragraph disabled>Конец ставок</Paragraph>
          <Paragraph>{date(auction.endDate)}</Paragraph>
        </Col>
        <Col xs={{ span: 8 }} className={styles.column}>
          <Paragraph disabled>Начальная цена</Paragraph>
          <Paragraph>{rub(auction.startCost, 0)}</Paragraph>
        </Col>
        <Col xs={{ span: 8 }} className={styles.column}>
          <Paragraph disabled>Действия</Paragraph>
          <Paragraph>
            <Space size="small">
              <Link to={`/auctions/${auction.originId}`}>
                <Button size="small">
                  <SearchOutlined />
                </Button>
              </Link>

              <a
                href={`https://zakupki.mos.ru/auction/${auction.originId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button size="small">
                  <ZakupkiIcon />
                </Button>
              </a>

              <Button
                size="small"
                onClick={() => removeAuction(auction.originId)}
                danger
              >
                <DeleteOutlined />
              </Button>
            </Space>
          </Paragraph>
        </Col>
      </Row>
    </Card>
  )
}
