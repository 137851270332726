import { Form, Input, InputNumber, Modal } from 'antd'
import React from 'react'

import { ICreateSearchProductDto } from '../../auctions.interface'

interface Props {
  visible: boolean
  confirmLoading: boolean
  onCreate: (values: ICreateSearchProductDto) => Promise<void>
  onCancel: () => void
}

export function SearchProductCreateForm({
  visible,
  onCreate,
  onCancel,
  confirmLoading,
}: Props) {
  const [form] = Form.useForm()

  return (
    <Modal
      visible={visible}
      title="Добавить цену на товар"
      okText="Добавить"
      cancelText="Отмена"
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={async () => {
        const values = await form.validateFields()
        try {
          await onCreate(values)
          form.resetFields()
        } catch (info) {
          console.log('Validate Failed:', info)
        }
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ cost: '', url: '' }}
      >
        <Form.Item
          name="cost"
          label="Цена"
          rules={[
            {
              required: true,
              message: 'Пожалуйста введите цену на товар!',
            },
          ]}
        >
          <InputNumber autoFocus />
        </Form.Item>
        <Form.Item
          name="url"
          label="Ссылка на товар"
          rules={[{ type: 'url', message: 'Не валидный url' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}
