import { message, Skeleton, Typography } from 'antd'
import React from 'react'
import { AddAuctionForm } from './AddAuctionForm'
import { useGetAuctionsQuery } from './auctions.api'
import { AuctionsList } from './AuctionsList'

const { Title } = Typography

export function AuctionsPage() {
  const {
    data: auctions = [],
    isLoading,
    isError,
    error = '',
  } = useGetAuctionsQuery(undefined, {
    pollingInterval: Number(process.env.REACT_APP_POLLING_INTERVAL || 10000),
  })

  const auctionsIds = auctions.map(auction => auction.originId)

  if (isError) {
    message.error(error.toString())
  }

  return (
    <section style={{ paddingTop: 16, minHeight: '100vh' }}>
      <Title level={3}> Котировочные сессии </Title>
      <AddAuctionForm auctionIds={auctionsIds} />
      <div style={{ marginTop: 16, marginBottom: 16 }}>
        {isLoading ? <Skeleton /> : <AuctionsList auctions={auctions} />}
      </div>
    </section>
  )
}
