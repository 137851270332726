import { DeleteOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { rub } from '../../../../app/utils'

import {
  useRemoveSearchProductMutation,
  useUpdateSearchProductMutation,
} from '../../auctions.api'
import { ISearchProduct } from '../../auctions.interface'

const { Paragraph, Text } = Typography

type SearchProductProps = {
  searchProduct: ISearchProduct
}

const getHostname = (url: string) => {
  try {
    return new URL(url).hostname
  } catch (error) {
    return ''
  }
}

export function SearchProductCardItem({ searchProduct }: SearchProductProps) {
  const [cost, setCost] = useState<number>(searchProduct.cost)
  const [url, setUrl] = useState<string>(searchProduct.url)
  const [hostname, setHostname] = useState<string>(
    getHostname(searchProduct.url)
  )
  const [removeSearchProduct] = useRemoveSearchProductMutation()
  const [updateSearchProduct] = useUpdateSearchProductMutation()

  useEffect(() => {
    updateSearchProduct({
      searchProductId: searchProduct.id,
      initialValue: { cost: Number(cost), url },
    })
  }, [cost, url, updateSearchProduct, searchProduct.id])

  useEffect(() => {
    setCost(searchProduct.cost)
    setUrl(searchProduct.url)
  }, [searchProduct.cost, searchProduct.url])

  return (
    <>
      <Divider />
      <Row>
        <Col xs={{ span: 12 }} sm={{ span: 6 }}>
          <Paragraph disabled>Цена за ед.</Paragraph>
          <Text
            editable={{
              tooltip: 'Нажми чтобы изменить цену',
              onChange: value => {
                const cost = Number(
                  value.replace(/[^0-9,.]/g, '').replace(',', '.')
                )
                setCost(cost)
              },
            }}
          >
            {rub(cost)}
          </Text>
        </Col>
        <Col xs={{ span: 12 }} sm={{ span: 6 }}>
          <Paragraph disabled>Ссылка на товар</Paragraph>
          <Typography.Link
            href={url}
            target="_blank"
            editable={{
              tooltip: 'Нажми чтобы изменить url',
              onStart: () => {
                setHostname(url)
              },
              onChange: value => {
                setUrl(value)
                setHostname(getHostname(value))
              },
              onCancel: () => {
                setHostname(getHostname(url))
              },
            }}
          >
            {hostname}
          </Typography.Link>
        </Col>
        <Col xs={{ span: 12 }} sm={{ span: 6 }}>
          <Paragraph disabled>Контакты</Paragraph>
          <div>
            <Text>Тут будут контакты</Text>
          </div>
        </Col>
        <Col xs={{ span: 12 }} sm={{ span: 6 }}>
          <Paragraph disabled>Действия</Paragraph>
          <Button
            onClick={() => removeSearchProduct(searchProduct.id)}
            size="small"
            danger
          >
            <DeleteOutlined />
          </Button>
        </Col>
      </Row>
    </>
  )
}
