import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import {
  IAuction,
  IAuctions,
  ICreateSearchProductDto,
  IUpdateSearchProductDto,
} from './auctions.interface'

export type CreateSearchProductQuery = {
  productId: number
  initialValue: ICreateSearchProductDto
}

export type UpdateSearchProductQuery = {
  searchProductId: number
  initialValue: IUpdateSearchProductDto
}

export const auctionsApiSlice = createApi({
  reducerPath: 'auctionsApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_SERVER }),
  tagTypes: ['Auction'],
  endpoints: builder => ({
    getAuctions: builder.query<IAuctions[], void>({
      query: () => 'auctions',
      providesTags: ['Auction'],
    }),

    getAuction: builder.query<IAuction, number>({
      query: auctionId => `auctions/${auctionId}`,
      providesTags: ['Auction'],
    }),

    addAuction: builder.mutation<IAuction, { auctionId: number }>({
      query: initialValue => ({
        url: 'auctions',
        method: 'POST',
        body: initialValue,
      }),
      invalidatesTags: ['Auction'],
    }),

    removeAuction: builder.mutation<IAuction, number>({
      query: auctionId => ({
        url: `auctions/${auctionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Auction'],
    }),

    addSearchProduct: builder.mutation<IAuction, CreateSearchProductQuery>({
      query: ({ productId, initialValue }) => ({
        url: `products/${productId}/search-products`,
        method: 'POST',
        body:
          initialValue.url === ''
            ? { ...initialValue, url: null }
            : initialValue,
      }),
      invalidatesTags: ['Auction'],
    }),

    updateSearchProduct: builder.mutation<IAuction, UpdateSearchProductQuery>({
      query: ({ searchProductId, initialValue }) => ({
        url: `search-products/${searchProductId}`,
        method: 'PATCH',
        body:
          initialValue.url === ''
            ? { ...initialValue, url: null }
            : initialValue,
      }),
      invalidatesTags: ['Auction'],
    }),

    removeSearchProduct: builder.mutation<IAuction, number>({
      query: searchProductId => ({
        url: `search-products/${searchProductId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Auction'],
    }),
  }),
})

export const {
  useGetAuctionsQuery,
  useGetAuctionQuery,
  useAddAuctionMutation,
  useRemoveAuctionMutation,
  useAddSearchProductMutation,
  useUpdateSearchProductMutation,
  useRemoveSearchProductMutation,
} = auctionsApiSlice
