import Icon from '@ant-design/icons'
import React from 'react'

const ZakupkiSvg = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    enableBackground="new 0 0 16 16"
    // xml:space="preserve"
  >
    <image
      id="image0"
      width="16"
      height="16"
      x="0"
      y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAgMAAABinRfyAAAAIGNIUk0AAHomAACAhAAA+gAAAIDo
AAB1MAAA6mAAADqYAAAXcJy6UTwAAAAMUExURf////8ASbaxs////44dKAYAAAABdFJOUwBA5thm
AAAAAWJLR0QAiAUdSAAAAAd0SU1FB+ULCgohD4OHfEAAAAOvelRYdFJhdyBwcm9maWxlIHR5cGUg
Z2lmOnhtcCBkYXRheG1wAABIiZVVSZITMRC86xU8QapFC7/RVgQHIjhw4Plkqe2xMTMEuMO9aKkl
KysVvny1zz+/ff+0+o+OZ/iEX4pUAlupJeaUOY+sRShmyppLbnnzIto2xjAijLcsPqKFVRZHWSUK
Y23NTWoovWCjcumyVTKeMMiMTURsvCnyLJV7qRkb8ZecKPpXnnkX9hk3HxCLZPMouF8TWKJn+Ynj
YQRjw7fI2w6KWmVpJEQWspUzxEqbMy1Ek1gYJrhww1jizJEbEwtNjBK+EhcyPP2emAmJBrx0DGXc
kRDFl4t4IzVCDFihJCL5JbFrbgWiXIvgityRjJXzo+3/E+3ltfl1oiDcCfd1mcCTCwoTHI1SkRLs
+/wN3lsMCABFQgkot4NSAzpYgW/MAl+CMyB6Irogfa6CQysLJV6v8R53+1EgvDPeFlnAtlSihw9g
8YTBda/Xk3Euos6v/JHx8J71V+POQHBIydwYUvV0CKv1Mu6QhPfS+p+U7k7D3es9mRs6DrMBZHV6
woxIk9Mg1xoYktMyHRWqkkBI0I5Rlioq4NEhWxJ0DEYEX5Er8spYmrDFf05OcPPZMdy24EE7BbIH
q3/4bb/7vbktf7oN/+oXXp1NCkTEiaqeuuV5777g4KH5IsTAzhI+b6cR0XL33vIGPdLicIP6D+re
0gs3YPVwN8MjPvDuFYlP7Olu5G8ghCcU4kkve6fjqeIqIHcUhLgBl4jRePApByOHg5Dz4dGz338v
+sOtm9fwsd+H14dfumkm2Wtbh9/7+nXZpUBvo0+Se6noNeO6H16EXy+Bd+eu2DGZ2bZl04Z1a1at
oOpqYozOSRa37b3XnmGP3XfbdZedt27xoHbacdnaa625xuqrrboKZEEvbVlpxWlzzzXnHLPPNmuY
ZeapUyZin2nGYWOPNSYOoT7aqKOMPHTI4EEjjdit77767KP33nrtOHa6dgkd7d1Tj83abqvNNlpv
tUE9mzZxfW2pxWp111VnHbXXVmsFVBVHSOVKNdVYIGy7rDLLwPnWcFSWkl06QQkqkAcvwBGZkZ2S
FQfnIS1QJWAb1XTr0qkjaNemVUEdVQUDlDRpFJMNOZkyBJ0tFdLkFAWpBJ0l0QvBC/Ua4FRDp+LI
zqzgEQ4ncMl1adPC+TWoU6OK8mUCB1wDCSdssrTTSjON1FNLNZWUk3qvh0QppRhRx4hSRKAZAUlE
VuhkuEb3Yjr8Ah7/+MsKysRgAAAAJklEQVQI12NgAAHG0NAQCKGZtWoKlHBgwkeEhkEJrVWrGiAE
CAAArTsSZ/uYDnYAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjEtMTEtMTBUMDc6MzM6MTUrMDM6MDCt
FiniAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIxLTExLTEwVDA3OjMzOjE1KzAzOjAw3EuRXgAAAABJ
RU5ErkJggg=="
    />
  </svg>
)

export const ZakupkiIcon = (props: any) => (
  <Icon component={ZakupkiSvg} {...props} />
)
