import { Col, Layout, Row } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'

import './App.less'
import { AuctionPage } from './features/auctions/AuctionPage'
import { AuctionsPage } from './features/auctions/AuctionsPage'

function App() {
  return (
    <Router>
      <Layout>
        <Content>
          <Row>
            <Col xs={{ span: 22, offset: 1 }} lg={{ span: 16, offset: 4 }}>
              <Switch>
                <Redirect exact from="/" to="/auctions" />
                <Route exact path="/auctions">
                  <AuctionsPage />
                </Route>
                <Route exact path="/auctions/:auctionId">
                  <AuctionPage />
                </Route>
              </Switch>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Router>
  )
}

export default App
