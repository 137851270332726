export const rub = (value: number, scale: 2 | 0 = 2) =>
  new Intl.NumberFormat('ru', {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: scale,
    maximumFractionDigits: scale,
  }).format(value)

export const units = (value: number, scale: 3 | 2 | 1 | 0 = 0) =>
  new Intl.NumberFormat('ru', {
    minimumFractionDigits: scale,
    maximumFractionDigits: scale,
  }).format(value)

export const percent = (value: number, scale: 2 | 1 | 0 = 0) =>
  new Intl.NumberFormat('ru', {
    style: 'percent',
    minimumFractionDigits: scale,
    maximumFractionDigits: scale,
  }).format(value)

export const date = (value: Date) =>
  Intl.DateTimeFormat('ru', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(value))
