import { PageHeader, Space, Typography } from 'antd'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { ZakupkiIcon } from '../../components/ZakupkiIcon/ZakupkiIcon'
import { AuctionAnalysisCard } from './AuctionAnalysisCard'
import { useGetAuctionQuery } from './auctions.api'
import { ProductCard } from './Product/ProductCard'

const { Paragraph } = Typography

export function AuctionPage() {
  const history = useHistory()

  const { auctionId } = useParams<{ auctionId?: string }>()
  const {
    data: auction,
    isLoading,
    isError,
    error,
  } = useGetAuctionQuery(Number(auctionId), {
    // pollingInterval: 1000,
    pollingInterval: Number(process.env.REACT_APP_POLLING_INTERVAL || 10000),
  })

  if (isLoading)
    return (
      <div style={{ minHeight: '100vh' }}>
        Загрузка котировочной сессии {auctionId}
      </div>
    )
  if (isError) return <div>{error}</div>
  if (!auction) return null

  return (
    <>
      <PageHeader onBack={() => history.goBack()} title={auction.originId} />
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Paragraph style={{ textAlign: 'center' }}>
          <a
            href={`https://zakupki.mos.ru/auction/${auction.originId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ZakupkiIcon /> {auction.name}
          </a>
        </Paragraph>
        <AuctionAnalysisCard auction={auction} />
        {[...auction.products]
          .sort((a, b) => b.total - a.total)
          .map(product => (
            <ProductCard key={product.originId} product={product} />
          ))}
      </Space>
    </>
  )
}
